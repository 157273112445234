import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type DisplayModeKey =
    | "farmland_bank"
    | "user_field"
    | "user_field_register"
    | "water_gate_management"
    | "user_field_picture_management"
    | "user_field_picture_analysis"


export const DISPLAY_MODE_LABELS: { [key in DisplayModeKey]: string } = {
    farmland_bank: "農地バンク",
    user_field: "圃場状態",
    user_field_register: "圃場登録",
    water_gate_management: "水門管理",
    user_field_picture_management: "写真管理",
    user_field_picture_analysis: "写真解析",
} as const

export type UserFieldDisplayModeKey =
    | "has-picture"
    | "thumbnail"
    | "temperature"
    | "sensing"
    | "has-soil-report"

export const USER_FIELD_DISPLAY_MODE_LABELS: { [key in UserFieldDisplayModeKey]: string } = {
    "has-picture": "写真有",
    "thumbnail": "サムネイル",
    "temperature": "気温",
    "sensing": "センシング",
    "has-soil-report": "土壌分析有",
} as const

export type UserFieldPictureManagerActionKey =
    | "change"
    | "delete"

export const USER_FIELD_PICTURE_MANAGER_ACTION_LABELS: { [key in UserFieldPictureManagerActionKey]: string } = {
    change: "紐付変更",
    delete: "写真削除",
} as const

type OptionsProperty<T> = {
    optionList: T[],
    current: T | undefined,
}

type UiOptions = {
    displayModeKey: OptionsProperty<DisplayModeKey>,
    userFieldDisplayModeKey: OptionsProperty<UserFieldDisplayModeKey>,
    userFieldPictureManagerActionKey: OptionsProperty<UserFieldPictureManagerActionKey>,
}

const defaultUiOptions: UiOptions = {
    displayModeKey: {
        optionList: [],
        current: "user_field",
    },
    userFieldDisplayModeKey: {
        optionList: [],
        current: undefined,
    },
    userFieldPictureManagerActionKey: {
        optionList: [],
        current: undefined,
    },
}

type DisplayProperty = {
    isOpen: boolean,
}

type UiDisplays = {
    appUserProfileForm: DisplayProperty,
    cultivationPlanManager: DisplayProperty,
    fileUploader: DisplayProperty,
    fileViewer: DisplayProperty & { fileId: string },
    imageUploader: DisplayProperty,
    loadFailuredUserFieldPictureList: DisplayProperty,
    sensingChart: DisplayProperty,
    soilConditionerManager: DisplayProperty,
    soilReport: DisplayProperty,
    userFieldAttributeForm: DisplayProperty,
    userFieldFileList: DisplayProperty,
    userFieldGroupMemo: DisplayProperty,
    userFieldList: DisplayProperty,
    userFieldManager: DisplayProperty,
    userFieldPictureList: DisplayProperty,
    userFieldPictureViewer:  DisplayProperty & { pictureId: string },
    userLocationHistory: DisplayProperty,
    waterGateList: DisplayProperty,
    wideOrthoimgAnalysisList: DisplayProperty,
    wideOrthoimgAnalysisRequest: DisplayProperty,
    wideOrthoimgUploader: DisplayProperty,
}

const defaultUiDisplays: UiDisplays = {
    appUserProfileForm: { isOpen: false },
    cultivationPlanManager: { isOpen: false },
    fileUploader: { isOpen: false },
    fileViewer: { isOpen: false, fileId: "" },
    imageUploader: { isOpen: false },
    loadFailuredUserFieldPictureList: { isOpen: false },
    sensingChart: { isOpen: false },
    soilConditionerManager: { isOpen: false },
    soilReport: { isOpen: false },
    userFieldAttributeForm: { isOpen: false },
    userFieldFileList: { isOpen: false },
    userFieldGroupMemo: { isOpen: false },
    userFieldList: { isOpen: false },
    userFieldManager: { isOpen: false },
    userFieldPictureList: { isOpen: false },
    userFieldPictureViewer: { isOpen: false, pictureId: "" },
    userLocationHistory: { isOpen: false },
    waterGateList: { isOpen: false },
    wideOrthoimgAnalysisList: { isOpen: false },
    wideOrthoimgAnalysisRequest: { isOpen: false },
    wideOrthoimgUploader: { isOpen: false },
}

export type UiState = {
    options: UiOptions,
    displays: UiDisplays,
}

export const defaultUiState: UiState = {
    options: { ...defaultUiOptions },
    displays: { ...defaultUiDisplays },
}

type PayloadActionOpenUi = {
    key: keyof Omit<
        UiDisplays,
        | "fileViewer"
        | "userFieldPictureViewer"
    >,
}

type PayloadActionOpenFileViewer = {
    key: "fileViewer",
    fileId: string,
}

type PayloadActionOpenUserFieldPictureViewer = {
    key: "userFieldPictureViewer",
    pictureId: string,
}

type PayloadActionCloseUi = {
    key: keyof UiDisplays,
}

const uiStateSlice = createSlice({
    name: "ui_state",
    initialState: defaultUiState,
    reducers: {
        initializeUiState: (state: any) => {
            for (const [key, value] of Object.entries(defaultUiOptions)) {
                state.options[key].optionList = [ ...value.optionList ]
                state.options[key].current = value.current
            }
            for (const [key, value] of Object.entries(defaultUiDisplays)) {
                state.displays[key] = { ...value }
            }
        },
        setDisplayModeKeyList: (state: UiState, action: PayloadAction<DisplayModeKey[]>) => {
            state.options.displayModeKey.optionList = action.payload
        },
        setCurrentDisplayModeKey: (state: UiState, action: PayloadAction<DisplayModeKey | undefined>) => {
            state.options.displayModeKey.current = action.payload
        },
        setUserFieldDisplayModeKeyList: (state: UiState, action: PayloadAction<UserFieldDisplayModeKey[]>) => {
            state.options.userFieldDisplayModeKey.optionList = action.payload
        },
        setCurrentUserFieldDisplayModeKey: (state: UiState, action: PayloadAction<UserFieldDisplayModeKey | undefined>) => {
            state.options.userFieldDisplayModeKey.current = action.payload
        },
        setUserFieldPictureManagerActionKeyList: (state: UiState, action: PayloadAction<UserFieldPictureManagerActionKey[]>) => {
            state.options.userFieldPictureManagerActionKey.optionList = action.payload
        },
        setCurrentUserFieldPictureManagerActionKey: (state: UiState, action: PayloadAction<UserFieldPictureManagerActionKey | undefined>) => {
            state.options.userFieldPictureManagerActionKey.current = action.payload
        },
        openUi: (
            state: UiState,
            action: PayloadAction<
                | PayloadActionOpenUi
                | PayloadActionOpenFileViewer
                | PayloadActionOpenUserFieldPictureViewer
            >
        ) => {
            const key = action.payload.key
            state.displays[key].isOpen = true
            switch (key) {
                case "fileViewer":
                    state.displays[key].fileId = action.payload.fileId
                    break
                case "userFieldPictureViewer":
                    state.displays[key].pictureId = action.payload.pictureId
                    break
            }
        },
        closeUi: (state: UiState, action: PayloadAction<PayloadActionCloseUi>) => {
            const key = action.payload.key
            state.displays[key].isOpen = false
            switch (key) {
                case "fileViewer":
                    state.displays[key].fileId = defaultUiDisplays[key].fileId
                    break
                case "userFieldPictureViewer":
                    state.displays[key].pictureId = defaultUiDisplays[key].pictureId
                    break
            }
        },
        setUserFieldPictureViewerPictureId: (state: UiState, action: PayloadAction<string>) => {
            state.displays.userFieldPictureViewer.pictureId = action.payload
        },
    }
})

export const {
    initializeUiState,
    setDisplayModeKeyList,
    setUserFieldDisplayModeKeyList,
    setCurrentDisplayModeKey,
    setCurrentUserFieldDisplayModeKey,
    setUserFieldPictureManagerActionKeyList,
    setCurrentUserFieldPictureManagerActionKey,
    openUi,
    closeUi,
    setUserFieldPictureViewerPictureId,
} = uiStateSlice.actions

export const uiStateReducer = uiStateSlice.reducer
